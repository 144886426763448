import { graphql } from 'gatsby';
import React from 'react';
import AuthorBio from '../components/BlogContent/AuthorBio';
import FilteredBlogPosts from '../components/FilteredBlogPosts';
import GeneralHead from '../components/generalHead';
import Layout from '../components/layout';
import SEO from '../components/seo';

const BlogAuthor = ({ data }) => {
  const {
    author,
    blogs: { nodes },
  } = data;

  return (
    <Layout forceAnnouncement forceShowHeader forceShowFooter>
      <FilteredBlogPosts
        blogs={nodes}
        title={`Posts by ${author.firstName} ${author.lastName}`}
      >
        <AuthorBio author={author} />
      </FilteredBlogPosts>
    </Layout>
  );
};

export const Head = ({
  data: {
    author: { seo, headHtml, indexable, schemaMarkup },
  },
}) => (
  <GeneralHead>
    <SEO
      seo={seo}
      headHtml={headHtml}
      indexable={indexable}
      schemaMarkup={schemaMarkup}
    />
  </GeneralHead>
);

export const query = graphql`
  query($slug: String!) {
    author: datoCmsPerson(slug: { eq: $slug }) {
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      indexable
      bio {
        blocks
        links
        value
      }
      funFact
      role
      firstName
      lastName
      headshot {
        image {
          gatsbyImageData
          url
          fluid {
            ...GatsbyDatoCmsFluid
          }
        }
      }
    }
    blogs: allDatoCmsBlogPost(
      sort: { fields: publishDate, order: DESC }
      filter: { author: { slug: { eq: $slug } } }
    ) {
      nodes {
        internalName
        id
        title
        excerpt
        slug
        publishDate(formatString: "MMM DD, YYYY")
        category {
          id
          name
          tagColor {
            hex
          }
          tagBackground {
            hex
          }
        }
        image {
          gatsbyImageData(
            imgixParams: {
              fm: "webp"
              auto: "compress"
              maxW: 1080
              fit: "clip"
              q: 35
            }
          )
          fluid(imgixParams: { fm: "webp", auto: "compress", q: 35 }) {
            ...GatsbyDatoCmsFluid
          }
          alt
          url
        }
        metaTags {
          description
        }
        contentWordCounter
      }
    }
  }
`;

export default BlogAuthor;
