import { render } from 'datocms-structured-text-to-plain-text';
import { isParagraph } from 'datocms-structured-text-utils';
import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { renderRule, StructuredText } from 'react-datocms';
import styled from 'styled-components';
import vehicleIcon from '../../images/vehicle.svg';
import Link from '../../utils/Link';
import Img from '../../utils/OptimizedImage';

const AuthorBio = ({ author, className, enableLink }) => {
  const bio = author?.bio?.value && render(author?.bio);
  const noBioOrFunFact = !author?.funFact && !bio;

  const bioContent = useMemo(
    () => (
      <StructuredText
        data={author?.bio}
        customNodeRules={[
          renderRule(isParagraph, ({ children }) => {
            return children[0] && <p>{children}</p>;
          }),
        ]}
      />
    ),
    [author.bio]
  );

  return (
    <AuthorDetail className={className}>
      <Row
        className={!author?.role || noBioOrFunFact ? 'align-items-center' : ''}
      >
        <Col lg="auto" md="auto">
          <div className="d-flex align-items-center">
            <div className="author-picture">
              {author?.headshot?.image?.gatsbyImageData ? (
                <Img
                  image={author?.headshot?.image?.gatsbyImageData}
                  alt={`${author?.firstName} ${author?.lastName}`}
                />
              ) : (
                <Img
                  src={author?.headshot?.image?.url}
                  alt={`${author?.firstName} ${author?.lastName}`}
                />
              )}
            </div>
            <div className="author-role d-inline-block d-md-none pl-3">
              <p className="name">
                {enableLink ? (
                  <Link to={`/blog/author/${author?.slug}`}>
                    <span>
                      {author?.firstName} {author?.lastName}
                    </span>
                  </Link>
                ) : (
                  <span>
                    {author?.firstName} {author?.lastName}
                  </span>
                )}
              </p>
              {author?.role && (
                <p className="role">{author?.role} at Shopmonkey</p>
              )}
            </div>
          </div>
        </Col>
        <Col>
          <div
            className={`author-role d-none d-md-block${
              noBioOrFunFact ? ' no-bio-fun' : ''
            }`}
          >
            <p className="name">
              {enableLink ? (
                <Link to={`/blog/author/${author?.slug}`}>
                  <span>
                    {author?.firstName} {author?.lastName}
                  </span>
                </Link>
              ) : (
                <span>
                  {author?.firstName} {author?.lastName}
                </span>
              )}
            </p>
            {author?.role && (
              <p className="role">{author?.role} at Shopmonkey</p>
            )}
          </div>
          {bio && (
            <div className={`author-bio${!author?.role ? ' no-role' : ''}`}>
              {bioContent}
            </div>
          )}
          {author?.funFact && (
            <div className="author-fun-fact">
              <Img
                src={vehicleIcon}
                alt={`car - ${author?.firstName} ${author?.lastName}`}
              />
              <span>{author?.funFact}</span>
            </div>
          )}
        </Col>
      </Row>
    </AuthorDetail>
  );
};

export const AuthorDetail = styled.div`
  padding: 40px;
  background: #f0f1f7;
  border-radius: 8px;
  position: relative;

  .author-picture {
    display: inline-block;
    vertical-align: middle;
    border-radius: 50%;
    width: 80px;
    height: 80px;

    img {
      border-radius: 50%;
      width: 80px;
      height: 80px;
    }
  }

  .author-role {
    display: inline-block;
    vertical-align: middle;
    font-style: normal;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: -0.27px;
    color: #171e2a;

    @media (max-width: 767.98px) {
      font-size: 16px;
    }

    p {
      margin-bottom: 0;

      @media (max-width: 767.98px) {
        display: block;
      }
    }

    .name {
      font-weight: 700;
      padding-right: 8px;
      letter-spacing: 0;
    }

    .role {
      font-weight: 400;
    }

    &.no-bio-fun {
      p {
        display: block;
      }
    }
  }

  .author-bio {
    margin-top: 16px;
    font-size: 16px;
    color: #2c3641;

    &.no-role {
      margin-top: 0;
    }

    p {
      margin-bottom: 0;
    }
  }

  .author-fun-fact {
    display: flex;
    font-size: 16px;
    color: #4d5061;
    margin-top: 16px;

    img {
      height: 24px;
    }

    span {
      padding-left: 8px;
      vertical-align: middle;
    }
  }
`;

export default AuthorBio;
